import {
  CancelForRereservationFragment,
  CancelRereservationStatus,
} from "gql/__generated__/graphql";

export const canEdit = (
  rereservationStatus: CancelRereservationStatus
): boolean => {
  return rereservationStatus === "INFORMATION_NOT_ENOUGH";
};

export const rereservationStatusText: Record<
  CancelRereservationStatus,
  string
> = {
  ENCOURAGING_RERESERVATION_NOT_SCHEDULED: "未送付",
  ENCOURAGING_RERESERVATION_SCHEDULED: "送付予定",
  CANNOT_ENCOURAGE: "送付対象外",
  ENCOURAGING_MESSAGE_SENT: "送付済",
  RERESERVED: "再予約済",
  RERESERVATION_VISITED: "来店済",
  RERESERVATION_CANCELED: "キャンセル済",
  INFORMATION_NOT_ENOUGH: "送付情報不足",
  ENCOURAGING_MESSAGE_ABORTED: "送付停止",

  // kokua
  RERESERVED_BY_REFUND_HUB: "再予約済",
  // RERESERVED_BY_OTHER_THAN_REFUND_HUB: "再予約済 (RefundHub以外)", # FIXME:
  RERESERVED_BY_OTHER_THAN_REFUND_HUB: "再予約済",
};

export const getSpanClass = (row: CancelForRereservationFragment, styles) => {
  let spanClass = "";
  if (
    row.rereservationStatus === "ENCOURAGING_RERESERVATION_NOT_SCHEDULED" ||
    row.rereservationStatus === "INFORMATION_NOT_ENOUGH"
  )
    spanClass = styles.background_light_orange;
  else if (row.rereservationStatus === "ENCOURAGING_RERESERVATION_SCHEDULED")
    spanClass = styles.background_light_green;
  else if (row.rereservationStatus === "ENCOURAGING_MESSAGE_SENT") {
    spanClass = styles.background_pink;
  } else if (
    row.rereservationStatus === "RERESERVED_BY_REFUND_HUB" ||
    row.rereservationStatus === "RERESERVED_BY_OTHER_THAN_REFUND_HUB" ||
    row.rereservationStatus === "RERESERVED"
  ) {
    spanClass = styles.background_blue;
  } else if (row.rereservationStatus === "RERESERVATION_VISITED") {
    spanClass = styles.background_deep_blue;
  } else if (
    row.rereservationStatus === "ENCOURAGING_MESSAGE_ABORTED" ||
    row.rereservationStatus === "RERESERVATION_CANCELED"
  ) {
    spanClass = styles.background_red;
  }

  return spanClass;
};
