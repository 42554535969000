export const isValidDate = (date?: Date): boolean => {
  return !isNaN(date?.getTime() ?? NaN);
};

export const formatDateUntilDay = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}/${month}/${day}`;
};

export const formatDateUntilDayByHyphen = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const formatHourMinuteOfDate = (date: Date): string => {
  const hour = date.getHours().toString().padStart(2, "0");
  const minute = date.getMinutes().toString().padStart(2, "0");
  return `${hour}:${minute}`;
};

export const formatDateUntilMinute = (date: Date): string => {
  return `${formatDateUntilDay(date)} ${formatHourMinuteOfDate(date)}`;
};

export const getWeekDay = (date: Date): string => {
  const weekDays = ["日", "月", "火", "水", "木", "金", "土"];
  return weekDays[date.getDay()];
};

export const convertGraphQLDateToDate = (dateStr: string): Date => {
  return new Date(dateStr);
};

export const convertDateToHumanReadableDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${year}/${month}/${day} ${hours}:${minutes}`;
};

export const addDate = (date: Date, toAdd: number): Date => {
  return new Date(date.getTime() + toAdd * 24 * 60 * 60 * 1000);
};

export const addMinute = (date: Date, toAdd: number): Date => {
  return new Date(date.getTime() + toAdd * 60 * 1000);
};

export const dayEquals = (a: Date, b: Date): boolean => {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  );
};

// toISOString: 2024-12-26T02:00:00.000Z
// getJSTISOString: 2024-12-26T11:00:00.000+09:00
export const getJSTISOString = (date: Date) => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .replace(/Z$/, "+09:00");
};
