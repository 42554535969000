import React, { useEffect, useState } from "react";
import styles from "./PaymentComplete.module.css";
import { useParams, useNavigate } from "react-router-dom";
import apiService from "components/provider/rest";
import CompleteIcon from "components/ui/CompleteIcon/CompleteIcon";
function PaymentComplete() {
  const navigate = useNavigate();
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [couponId, setCouponId] = useState<string | undefined>(undefined);
  const [couponIssuedText, setCouponIssuedText] = useState<string | undefined>(
    undefined
  );
  const [checkCouponText, setCheckCouponText] = useState<string | undefined>(
    undefined
  );
  const paymentID = useParams().payment_id;
  useEffect(() => {
    const confirmPayment = async () => {
      await apiService
        .post("/public/payment/confirm", {
          payment_id: paymentID,
        })
        .then((res) => {
          console.log("resj");
          setAmount(res.amount);
          setCouponId(res.coupon_id);
          setCouponIssuedText(res.coupon_issued_text);
          setCheckCouponText(res.check_coupon_text);
          return res;
        })
        .catch((error: Error) => {
          console.log("error", error.message);
          alert("支払いは完了していません。");
          navigate("/payment/" + paymentID);
        });
    };
    confirmPayment();
  }, [paymentID]);
  if (!amount) {
    return <div>読み込み中...</div>;
  }
  return (
    <div className={styles.main_content}>
      <CompleteIcon />
      <h2>お支払いが完了しました</h2>
      <p>￥{amount}の決済が正常に行われました</p>
      {couponId && couponIssuedText && checkCouponText && (
        <>
          <h3 className={styles.coupon_text}>{couponIssuedText}</h3>
          <p>使用期限がありますのでご注意ください</p>
          <button
            className={`common-button ${styles.payment_button}`}
            onClick={() => {
              navigate(`/coupon/${couponId}`);
            }}
          >
            {checkCouponText}
          </button>
        </>
      )}
    </div>
  );
}

export default PaymentComplete;
