import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import _ from "lodash";
import reloadImage from "images/reload.svg";
import styles from "./HomePage.module.scss";
import "common/index.css";
import { useHotkeys } from "react-hotkeys-hook";
import {
  isValidCancelTypeToSendInvoice,
  shouldUseDefaultCouponMaster,
} from "domain/home";
import { isValidPhoneNumber } from "utils/phoneNumber";
import {
  EditingRereservationRow,
  EditingRow,
  Tab,
  confirmUpdatePaymentStatusButtonMessage,
} from "components/features/home/HomePage.logic";
import CancelsTableView from "components/features/home/CancelsTableView/CancelsTableView";
import MobileCancelsTableView from "components/features/home/CancelsTableView/MobileCancelsTableView";
import {
  BulkUpdateCancelPaymentStatusDocument,
  CancelPaymentStatus,
  CancelForListFragment,
  CancelForListFragmentDoc,
  GetCouponMastersDocument,
  GetCouponMastersQuery,
  GetCouponMastersQueryVariables,
  GetMeDocument,
  GetMeQuery,
  GetMeQueryVariables,
  CouponMasterForListFragmentDoc,
  UpdateCancelDocument,
  CouponMasterNameFragmentDoc,
  SendInvoiceDocument,
  SalonAllFieldFragmentDoc,
  GetClaimedCancelsQuery,
  GetClaimedCancelsDocument,
  GetNotClaimedCancelsQuery,
  GetNotClaimedCancelsDocument,
  GetPaidCancelsQuery,
  GetPaidCancelsDocument,
  GetNotSubjectCancelsQuery,
  GetNotSubjectCancelsDocument,
  AbortClaimDocument,
  GetInvoicePriceDocument,
  GetInvoicePriceQuery,
  GetInvoicePriceQueryVariables,
  CancelType,
  GetEncourageRereservationMessageNotSentCancelsQuery,
  GetEncourageRereservationMessageNotSentCancelsDocument,
  GetEncourageRereservationMessageNotSentCancelsQueryVariables,
  GetEncourageRereservationMessageSentCancelsDocument,
  GetRereservedCancelsQuery,
  GetRereservedCancelsDocument,
  CancelForRereservationFragmentDoc,
  GetEncourageRereservationMessageSentCancelsQuery,
  ScheduleEncourageRereservationDocument,
  UpdateCancelForRereservationDocument,
} from "gql/__generated__/graphql";
import { useMediaQueryContext } from "components/provider/MediaQueryProvider";
import MobileSummaryItem from "./Summary/MobileSummaryItem";
import * as Tabs from "@radix-ui/react-tabs";
import { toStringWithCommas } from "utils/strings/number";
import {
  showUpdatePaymentStatusButton,
  updatePaymentStatusButtonTitle,
} from "./CancelsTableView/CancelsTableView.common.logic";
import { getFragmentData } from "gql/__generated__";
import {
  invalidCancelTypeToSendInvoiceMessage,
  invalidPhoneNumberMessage,
} from "./home.common.logic";
import { useLoadCancelsModal } from "./LoadCancelsModal/LoadCancelsModal";
import { convertCancelTypeToCreateCancelCancelTypeInput } from "domain/cancel";
import CancelsForRereservationTableView from "./CancelsTableView/CancelsForRereservationTableView";
import MobileCancelsForRereservationView from "./CancelsTableView/MobileCancelsForRereservationTableView";
import classNames from "classnames";
import { useFeatureFlag } from "components/provider/apollo/featureFlag";

const calcSummaryPrice = (
  cancels: readonly CancelForListFragment[],
  paymentStatus: CancelPaymentStatus
) => {
  let totalPrice = 0;
  for (const cancel of cancels) {
    if (cancel.paymentStatus === paymentStatus) {
      totalPrice += cancel.invoicePrice;
    }
  }
  return totalPrice;
};

// デカくなってきたので、カスタムhook作ってfile切り分けたい
const HomePage = () => {
  document.title = "RefundHub | ホーム";
  const apolloClient = useApolloClient();

  const { isMobile } = useMediaQueryContext();
  const tabsRef = useRef<HTMLDivElement>(null);

  const [editingRowMap, setEditingRowMap] = useState<
    Record<string, EditingRow>
  >({}); // key is row idv2

  const [editingRereservationRowMap, setEditingRereservationRowMap] = useState<
    Record<string, EditingRereservationRow>
  >({}); // key is row idv2

  const [checkedNotSentInvoiceCancelMap, setCheckedNotSentInvoiceCancelMap] =
    useState<Record<number, CancelForListFragment>>({}); // key is cancel id
  const [checkedNotSubjectCancelIdMap, setCheckedNotSubjectCancelIdMap] =
    useState<Record<number, CancelForListFragment>>({}); // key is cancel id

  const defaultTab = "INVOICE_NOT_SENT";
  const [currentTab, setCurrentTab] = useState<Tab>(defaultTab);
  const currentTabCheckedCancelIdMap = () => {
    switch (currentTab) {
      case "INVOICE_NOT_SENT":
        return checkedNotSentInvoiceCancelMap;
      case "NOT_SUBJECT":
        return checkedNotSubjectCancelIdMap;
      default:
        return {};
    }
  };

  const { data: meData } = useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    { fetchPolicy: "cache-first" }
  );
  const salon = getFragmentData(SalonAllFieldFragmentDoc, meData?.me.salon);
  const selectableCancelTypes = salon?.selectableCancelTypes ?? [];
  const canSync = salon?.canEnqueueSyncCancels ?? false;
  const enableDefaultCouponMasterForNoConfirmationCancel =
    salon?.enableDefaultCouponMasterForNoConfirmationCancel ?? false;
  const defaultCouponMaster =
    getFragmentData(CouponMasterNameFragmentDoc, salon?.defaultCouponMaster) ??
    undefined;
  const copySalonIdToClipboard = useCallback(
    (event: KeyboardEvent) => {
      if (!salon) {
        return;
      }
      navigator.clipboard.writeText(salon.id.toString());
    },
    [salon]
  );
  useHotkeys("ctrl+shift+c", copySalonIdToClipboard);

  const { data: notClaimedCancelsData, refetch: refetchNotClaimedCancels } =
    useQuery<GetNotClaimedCancelsQuery>(GetNotClaimedCancelsDocument, {
      fetchPolicy: "no-cache",
    });
  const invoiceNotSentCancels = useMemo(() => {
    // fragmentをuseEffectのdependencyにする場合、useMemoが必要
    return (
      getFragmentData(
        CancelForListFragmentDoc,
        notClaimedCancelsData?.notClaimedCancels.cancels
      ) ?? []
    );
  }, [notClaimedCancelsData]);
  const invoiceNotSentSummaryPrice = calcSummaryPrice(
    invoiceNotSentCancels,
    "INVOICE_NOT_SENT"
  );

  const { data: claimedCancelsData, refetch: refetchClaimedCancelsData } =
    useQuery<GetClaimedCancelsQuery>(GetClaimedCancelsDocument, {
      fetchPolicy: "no-cache",
    });
  const invoiceSentCancels =
    getFragmentData(
      CancelForListFragmentDoc,
      claimedCancelsData?.claimedCancels.cancels
    ) ?? [];
  const claimedCancelsCount = invoiceSentCancels.filter(
    (c) => c.paymentStatus === "INVOICE_SENT"
  ).length;
  const invoiceSentSummaryPrice = calcSummaryPrice(
    invoiceSentCancels,
    "INVOICE_SENT"
  );

  const { data: paidCancelsData, refetch: refetchPaidCancelsData } =
    useQuery<GetPaidCancelsQuery>(GetPaidCancelsDocument, {
      fetchPolicy: "no-cache",
    });
  const paidCancels =
    getFragmentData(
      CancelForListFragmentDoc,
      paidCancelsData?.paidCancels.cancels
    ) ?? [];
  const paidSummaryPrice = calcSummaryPrice(paidCancels, "PAID");

  const { data: notSubjectCancelsData, refetch: refetchNotSubjectCancelsData } =
    useQuery<GetNotSubjectCancelsQuery>(GetNotSubjectCancelsDocument, {
      fetchPolicy: "no-cache",
    });
  const notSubjectCancels = useMemo(() => {
    return (
      getFragmentData(
        CancelForListFragmentDoc,
        notSubjectCancelsData?.notSubjectCancels.cancels
      ) ?? []
    );
  }, [notSubjectCancelsData]);

  const { featureFlag } = useFeatureFlag();
  const is_encouraging_rereservation_enabled = useMemo(() => {
    return featureFlag?.isEncouragingRereservationEnabled ?? false;
  }, [featureFlag]);

  const {
    data: encourageRereservationMessageNotSentCancelsData,
    refetch: refetchEncourageRereservationMessageNotSentCancelsData,
  } = useQuery<GetEncourageRereservationMessageNotSentCancelsQuery>(
    GetEncourageRereservationMessageNotSentCancelsDocument,
    {
      fetchPolicy: "no-cache",
    }
  );
  const encourageRereservationMessageNotSentCancels =
    getFragmentData(
      CancelForRereservationFragmentDoc,
      encourageRereservationMessageNotSentCancelsData
        ?.encourageRereservationMessageNotSentCancels.cancels
    ) ?? [];

  const {
    data: encourageRereservationMessageSentCancelsData,
    refetch: refetchEncourageRereservationotScheduledCancelsData,
  } =
    useQuery<GetEncourageRereservationMessageSentCancelsQuery>(
      GetEncourageRereservationMessageSentCancelsDocument,
      {
        fetchPolicy: "no-cache",
      }
    ) ?? [];
  const encourageRereservationMessageSentCancels =
    getFragmentData(
      CancelForRereservationFragmentDoc,
      encourageRereservationMessageSentCancelsData
        ?.encourageRereservationMessageSentCancels.cancels
    ) ?? [];

  const { data: rereservedCancelsData, refetch: refetchRereservedCancelsData } =
    useQuery<GetRereservedCancelsQuery>(GetRereservedCancelsDocument, {
      fetchPolicy: "no-cache",
    });
  const rereservedCancels =
    getFragmentData(
      CancelForRereservationFragmentDoc,
      rereservedCancelsData?.rereservedCancels.cancels
    ) ?? [];

  const onSuccessLoadCancels = useCallback(() => {
    refetchNotClaimedCancels();
    refetchClaimedCancelsData();
    refetchNotSubjectCancelsData();
    refetchPaidCancelsData();
    refetchEncourageRereservationMessageNotSentCancelsData();
    refetchEncourageRereservationotScheduledCancelsData();
    refetchRereservedCancelsData();
  }, [
    refetchNotClaimedCancels,
    refetchClaimedCancelsData,
    refetchNotSubjectCancelsData,
    refetchPaidCancelsData,
    refetchEncourageRereservationMessageNotSentCancelsData,
    refetchEncourageRereservationotScheduledCancelsData,
    refetchRereservedCancelsData,
  ]);
  const { renderModal, startLoadCancels } =
    useLoadCancelsModal(onSuccessLoadCancels);

  const { data: availableCouponMasterData } = useQuery<
    GetCouponMastersQuery,
    GetCouponMastersQueryVariables
  >(GetCouponMastersDocument, {
    variables: { param: { isActive: true } },
    fetchPolicy: "no-cache",
  });
  const couponMasters = getFragmentData(
    CouponMasterForListFragmentDoc,
    availableCouponMasterData?.couponMasters
  );

  const [mutateBulkUpdateCancelPaymentStatus] = useMutation(
    BulkUpdateCancelPaymentStatusDocument
  );
  const [mutateUpdateCancel] = useMutation(UpdateCancelDocument);
  const [mutateUpdateCancelForRereservation] = useMutation(
    UpdateCancelForRereservationDocument
  );
  const [mutateSendInvoice] = useMutation(SendInvoiceDocument);
  const [mutateAbortClaim] = useMutation(AbortClaimDocument);
  const [mutateScheduleEncourageRereservation] = useMutation(
    ScheduleEncourageRereservationDocument
  );

  const onTabValueChanged = (value: string) => {
    // NOTE: 請求送信時にcache更新の方が正しいが、めんどいので一旦refetchで更新
    switch (value) {
      case "INVOICE_NOT_SENT":
        setCurrentTab("INVOICE_NOT_SENT");
        refetchNotClaimedCancels();
        break;
      case "INVOICE_SENT":
        setCurrentTab("INVOICE_SENT");
        refetchClaimedCancelsData();
        break;
      case "PAID":
        setCurrentTab("PAID");
        refetchPaidCancelsData();
        break;
      case "NOT_SUBJECT":
        setCurrentTab("NOT_SUBJECT");
        refetchNotSubjectCancelsData();
        break;
      case "ENCOURAGING_RERESERVATION_NOT_SCHEDULED":
        setCurrentTab("ENCOURAGING_RERESERVATION_NOT_SCHEDULED");
        refetchEncourageRereservationMessageNotSentCancelsData();
        break;
      case "ENCOURAGING_RERESERVATION_SCHEDULED":
        setCurrentTab("ENCOURAGING_RERESERVATION_SCHEDULED");
        refetchEncourageRereservationotScheduledCancelsData();
        break;
      case "RERESERVED":
        setCurrentTab("RERESERVED");
        refetchRereservedCancelsData();
        break;
      default: // unexpected
    }
  };

  const updateRow = (idv2: string, row: EditingRow) => {
    const newMap = { ...editingRowMap };
    newMap[idv2] = row;
    setEditingRowMap(newMap);
  };
  const updateEditingRereservationRow = (
    idv2: string,
    row: EditingRereservationRow
  ) => {
    const newMap = { ...editingRereservationRowMap };
    newMap[idv2] = row;
    setEditingRereservationRowMap(newMap);
  };

  const onClickRowEdit = (idv2: string) => {
    const cancelRow = invoiceNotSentCancels.find((row) => row.idv2 === idv2);
    if (!cancelRow) {
      console.log("unexpectedly cancelRow is undefined. idv2=", idv2);
      return;
    }

    let couponMasterId: string | undefined = getFragmentData(
      CouponMasterNameFragmentDoc,
      cancelRow.couponMaster
    )?.id;
    if (
      shouldUseDefaultCouponMaster(
        "INVOICE_NOT_SENT",
        cancelRow,
        enableDefaultCouponMasterForNoConfirmationCancel,
        defaultCouponMaster
      )
    ) {
      couponMasterId = defaultCouponMaster?.id;
    }

    const row: EditingRow = {
      cancelType: cancelRow.cancelType,
      originPrice: cancelRow.originPrice.toString(),
      invoicePrice: cancelRow.invoicePrice,
      phoneNumber: cancelRow.phoneNumber,
      name: cancelRow.name,
      couponMasterId,
    };
    updateRow(idv2, row);
  };
  const onClickRereservationRowEdit = (idv2: string) => {
    const cancelRow = encourageRereservationMessageNotSentCancels.find(
      (row) => row.idv2 === idv2
    );
    if (!cancelRow) {
      console.log("unexpectedly cancelRow is undefined. idv2=", idv2);
      return;
    }

    const row: EditingRereservationRow = {
      phoneNumber: cancelRow.phoneNumber,
    };
    updateEditingRereservationRow(idv2, row);
  };

  const cancelRowEdit = (idv2: string) => {
    const newMap = { ...editingRowMap };
    delete newMap[idv2];
    setEditingRowMap(newMap);
  };
  const cancelRereservationRowEdit = (idv2: string) => {
    const newMap = { ...editingRereservationRowMap };
    delete newMap[idv2];
    setEditingRereservationRowMap(newMap);
  };

  const onCalcInvoicePrice = async (
    newCancelType: CancelType,
    newOriginPriceStr: string
  ): Promise<number> => {
    if (newCancelType === "UNSPECIFIED") {
      // NOTE: convertCancelTypeToCreateCancelCancelTypeInput throw a error
      // if UNSPECIFIED passed
      return 0;
    }

    const invoicePriceRes = await apolloClient.query<
      GetInvoicePriceQuery,
      GetInvoicePriceQueryVariables
    >({
      query: GetInvoicePriceDocument,
      variables: {
        param: {
          originPrice: Number(newOriginPriceStr ?? 0),
          cancelType:
            convertCancelTypeToCreateCancelCancelTypeInput(newCancelType),
        },
      },
    });
    return invoicePriceRes.data.invoicePrice.invoicePrice;
  };

  const onEditCancelType = async (idv2: string, newCancelType: CancelType) => {
    const row = editingRowMap[idv2];
    const invoicePrice = await onCalcInvoicePrice(
      newCancelType,
      row.originPrice
    );
    const newEditingRow: EditingRow = {
      ...row,
      cancelType: newCancelType,
      invoicePrice,
    };
    updateRow(idv2, newEditingRow);
  };

  const onEditOriginPrice = async (idv2: string, newOriginPriceStr: string) => {
    const row = editingRowMap[idv2];
    const invoicePrice = await onCalcInvoicePrice(
      row.cancelType,
      newOriginPriceStr
    );

    const newEditingRow: EditingRow = {
      ...row,
      originPrice: newOriginPriceStr,
      invoicePrice,
    };
    updateRow(idv2, newEditingRow);
  };

  const onClickUpdateButton = async (idv2: string) => {
    const editingRow = editingRowMap[idv2];
    if (!isValidPhoneNumber(editingRow.phoneNumber)) {
      alert("不正な形式の電話番号です。");
      return;
    }

    await mutateUpdateCancel({
      variables: {
        input: {
          id: idv2,
          phoneNumber: editingRow.phoneNumber,
          originPrice: Number(editingRow.originPrice),
          cancelType: editingRow.cancelType,
          name: editingRow.name,
          couponMasterId: editingRow.couponMasterId,
        },
      },
    }).then(() => {
      cancelRowEdit(idv2);
      refetchNotClaimedCancels();
      alert("キャンセル情報を更新しました");
    });
  };
  const onClickUpdateButtonForRereservation = async (idv2: string) => {
    const editingRow = editingRereservationRowMap[idv2];
    if (!isValidPhoneNumber(editingRow.phoneNumber)) {
      alert("不正な形式の電話番号です。");
      return;
    }

    await mutateUpdateCancelForRereservation({
      variables: {
        input: {
          id: idv2,
          phoneNumber: editingRow.phoneNumber,
        },
      },
    }).then(() => {
      cancelRereservationRowEdit(idv2);
      refetchEncourageRereservationMessageNotSentCancelsData();
      alert("キャンセル情報を更新しました");
    });
  };
  const onClickSendInvoice = async (idv2: string) => {
    const cancelRow = invoiceNotSentCancels.find((row) => row.idv2 === idv2);
    if (!cancelRow) {
      // TODO: これで未請求タブでしか何も起こらないようになってるが、制御方法雑なのでなんとかしたい
      console.log("unexpectedly cancelRow is undefined. idv2=", idv2);
      return;
    }

    let errorMessage = "";
    if (!isValidPhoneNumber(cancelRow.phoneNumber)) {
      errorMessage = invalidPhoneNumberMessage;
    }
    if (!isValidCancelTypeToSendInvoice(cancelRow.cancelType)) {
      if (errorMessage) {
        errorMessage += "\n" + invalidCancelTypeToSendInvoiceMessage;
      } else {
        errorMessage = invalidCancelTypeToSendInvoiceMessage;
      }
    }
    if (errorMessage) {
      alert(errorMessage);
      return;
    }

    // 50円未満の決済はstripeでできない
    // https://docs.stripe.com/currencies#minimum-and-maximum-charge-amounts
    if (cancelRow.invoicePrice < 50) {
      alert("金額が小さいため、請求できません。予約金額を確認してください。");
      return;
    }

    if (window.confirm("請求を送信します。よろしいですか？")) {
      await mutateSendInvoice({
        variables: {
          input: {
            cancelId: cancelRow.idv2,
          },
        },
      }).then(() => {
        refetchNotClaimedCancels();
        refetchClaimedCancelsData();
        alert("請求を送信しました。");
      });
    }
  };
  const onClickScheduleEncourageRereservation = async (idv2: string) => {
    const cancelRow = encourageRereservationMessageNotSentCancels.find(
      (row) => row.idv2 === idv2
    );
    if (!cancelRow) {
      console.log("unexpectedly cancelRow is undefined. idv2=", idv2);
      return;
    }

    if (window.confirm("再予約案内を送付します。よろしいですか？")) {
      await mutateScheduleEncourageRereservation({
        variables: {
          input: {
            cancelId: cancelRow.idv2,
          },
        },
      }).then(() => {
        refetchEncourageRereservationMessageNotSentCancelsData();
        refetchEncourageRereservationotScheduledCancelsData();
        alert("完了しました。");
      });
    }
  };

  // 請求済みタブのみ
  const onClickAbortClaim = async (idv2: string) => {
    const cancelRow = invoiceSentCancels.find((row) => row.idv2 === idv2);
    if (!cancelRow) {
      // ここにはこないはず
      return;
    }

    if (cancelRow.paymentStatus === "CLAIM_ABORTED") {
      // すでに取消済
      return;
    }

    if (window.confirm("請求を取り消します。よろしいですか？")) {
      await mutateAbortClaim({
        variables: {
          input: {
            cancelId: cancelRow.idv2,
          },
        },
      }).then(() => {
        refetchNotClaimedCancels();
        refetchClaimedCancelsData();
        alert("請求を取り消しました。");
      });
    }
  };

  const onClickCheckbox = (
    cancel: CancelForListFragment,
    currentTab: "INVOICE_NOT_SENT" | "NOT_SUBJECT"
  ) => {
    if (currentTab === "INVOICE_NOT_SENT") {
      if (cancel.id in checkedNotSentInvoiceCancelMap) {
        delete checkedNotSentInvoiceCancelMap[cancel.id];
        const newMap = _.cloneDeep(checkedNotSentInvoiceCancelMap);
        setCheckedNotSentInvoiceCancelMap(newMap);
      } else {
        checkedNotSentInvoiceCancelMap[cancel.id] = cancel;
        const newMap = _.cloneDeep(checkedNotSentInvoiceCancelMap);
        setCheckedNotSentInvoiceCancelMap(newMap);
      }
    } else {
      if (cancel.id in checkedNotSubjectCancelIdMap) {
        delete checkedNotSubjectCancelIdMap[cancel.id];
        const newMap = _.cloneDeep(checkedNotSubjectCancelIdMap);
        setCheckedNotSubjectCancelIdMap(newMap);
      } else {
        checkedNotSubjectCancelIdMap[cancel.id] = cancel;
        const newMap = _.cloneDeep(checkedNotSubjectCancelIdMap);
        setCheckedNotSubjectCancelIdMap(newMap);
      }
    }
  };
  const onClickUpdatePaymentStatusButton = async () => {
    let cancelIds: number[] = [];
    let paymentStatus;
    let confirmMessage = "";
    if (currentTab === "INVOICE_NOT_SENT" && notClaimedCancelsData) {
      paymentStatus = "NOT_SUBJECT";
      cancelIds = Object.keys(checkedNotSentInvoiceCancelMap).map((cancelId) =>
        parseInt(cancelId, 10)
      );
      confirmMessage = confirmUpdatePaymentStatusButtonMessage(
        invoiceNotSentCancels,
        checkedNotSentInvoiceCancelMap,
        currentTab
      );
    } else if (currentTab === "NOT_SUBJECT" && notSubjectCancelsData) {
      paymentStatus = "INVOICE_NOT_SENT";
      cancelIds = Object.keys(checkedNotSubjectCancelIdMap).map((cancelId) =>
        parseInt(cancelId, 10)
      );
      confirmMessage = confirmUpdatePaymentStatusButtonMessage(
        notSubjectCancels,
        checkedNotSubjectCancelIdMap,
        currentTab
      );
    } else {
      return; // unexpected
    }

    if (window.confirm(confirmMessage)) {
      await mutateBulkUpdateCancelPaymentStatus({
        variables: {
          input: {
            paymentStatus,
            cancelIds,
          },
        },
      });

      refetchNotClaimedCancels();
      refetchNotSubjectCancelsData();
      alert("キャンセル情報を更新しました");
    }
  };
  const onClickScrollUpTriangle = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollIntoView({
        behavior: "smooth",
        // block: "center",
      });
    }
  };

  useEffect(() => {
    // 未請求に無いものは選択されたsetから消す
    const newMap = {};
    Object.entries(checkedNotSentInvoiceCancelMap).forEach(([cancelId, _]) => {
      if (
        invoiceNotSentCancels.find(
          (cancel) => cancel.id.toString() === cancelId
        )
      ) {
        newMap[cancelId] = checkedNotSentInvoiceCancelMap[cancelId];
      }
    });
    if (_.size(newMap) !== _.size(checkedNotSentInvoiceCancelMap)) {
      setCheckedNotSentInvoiceCancelMap(newMap);
    }
  }, [invoiceNotSentCancels, checkedNotSentInvoiceCancelMap]);
  useEffect(() => {
    // 非請求に無いものは選択されたsetから消す
    // 将来的にpaginationを追加したらこの処理は必要だが、今はなくてもいい
    const newMap = {};
    Object.entries(checkedNotSubjectCancelIdMap).forEach(([cancelId, _]) => {
      if (
        notSubjectCancels.find((cancel) => cancel.id.toString() === cancelId)
      ) {
        newMap[cancelId] = checkedNotSubjectCancelIdMap[cancelId];
      }
    });
    if (_.size(newMap) !== _.size(checkedNotSubjectCancelIdMap)) {
      setCheckedNotSubjectCancelIdMap(newMap);
    }
  }, [notSubjectCancels, checkedNotSubjectCancelIdMap]);

  return (
    <div className={styles.home}>
      {renderModal()}
      <div className={styles.main_top_content}>
        <h1 className={styles.title}>
          {canSync && (
            <img
              src={reloadImage}
              alt="sync"
              className={styles.reload_button}
              onClick={startLoadCancels}
            />
          )}
          &ensp;キャンセル情報一覧
        </h1>
        {/* enableする予定
        {!isMobile && (
          <button
            className={styles.location_reload_button}
            onClick={() => {
              window.location.reload();
            }}
          >
            リロード
          </button>
        )} */}
      </div>

      {isMobile ? (
        <div className={styles.mobile_summary}>
          <MobileSummaryItem
            title="未請求"
            amount={invoiceNotSentSummaryPrice}
          />
          <MobileSummaryItem title="請求済" amount={invoiceSentSummaryPrice} />
          <MobileSummaryItem title="回収済" amount={paidSummaryPrice} />
        </div>
      ) : (
        <div className={styles.summary}>
          <div className={styles.summary__item}>
            <p className={styles.summary__item_title}>未請求</p>
            <div className={styles.summary__item_value}>
              <p className={styles.summary__item_value_amount}>
                ¥
                <span className={styles.amount_num}>
                  {toStringWithCommas(invoiceNotSentSummaryPrice)}
                </span>
              </p>
              <p className={styles.summary__item_value_count}>
                {invoiceNotSentCancels.length}件
              </p>
            </div>
          </div>
          <div className={styles.summary__item}>
            <p className={styles.summary__item_title}>請求済</p>
            <div className={styles.summary__item_value}>
              <p className={styles.summary__item_value_amount}>
                ¥
                <span className={styles.amount_num}>
                  {toStringWithCommas(invoiceSentSummaryPrice)}
                </span>
              </p>
              <p className={styles.summary__item_value_count}>
                {claimedCancelsCount}件
              </p>
            </div>
          </div>
          <div className={styles.summary__item}>
            <p className={styles.summary__item_title}>回収済</p>
            <div className={styles.summary__item_value}>
              <p className={styles.summary__item_value_amount}>
                ¥
                <span className={styles.amount_num}>
                  {toStringWithCommas(paidSummaryPrice)}
                </span>
              </p>
              <p className={styles.summary__item_value_count}>
                {paidCancels.length}件
              </p>
            </div>
          </div>
        </div>
      )}

      <div className={styles.main_bottom_content}>
        <Tabs.Root defaultValue={defaultTab} onValueChange={onTabValueChanged}>
          <Tabs.List ref={tabsRef} className={styles.tabs_container}>
            <Tabs.Trigger value="INVOICE_NOT_SENT" className={styles.tab}>
              未請求
            </Tabs.Trigger>
            <Tabs.Trigger value="INVOICE_SENT" className={styles.tab}>
              請求済
            </Tabs.Trigger>
            <Tabs.Trigger value="PAID" className={styles.tab}>
              回収済
            </Tabs.Trigger>
            <Tabs.Trigger value="NOT_SUBJECT" className={styles.tab}>
              非請求
            </Tabs.Trigger>
            {is_encouraging_rereservation_enabled && (
              <div className={styles.encouraging_rereservation_tabs_container}>
                <Tabs.Trigger
                  value="ENCOURAGING_RERESERVATION_NOT_SCHEDULED"
                  className={classNames(
                    styles.tab,
                    styles.encouraging_rereservation_not_scheduled_tab
                  )}
                >
                  再予約案内未送付
                </Tabs.Trigger>
                <Tabs.Trigger
                  value="ENCOURAGING_RERESERVATION_SCHEDULED"
                  className={styles.tab}
                >
                  送付済
                </Tabs.Trigger>
                <Tabs.Trigger value="RERESERVED" className={styles.tab}>
                  再予約済
                </Tabs.Trigger>
              </div>
            )}
            {!isMobile &&
              showUpdatePaymentStatusButton(
                currentTab,
                currentTabCheckedCancelIdMap()
              ) && (
                <div className={styles.update_payment_status_container}>
                  <span className={styles.selected_count}>
                    {_.size(currentTabCheckedCancelIdMap())}件選択
                  </span>
                  <button onClick={() => onClickUpdatePaymentStatusButton()}>
                    {updatePaymentStatusButtonTitle(currentTab)}
                  </button>
                </div>
              )}
          </Tabs.List>
          {isMobile ? (
            <div>
              <Tabs.Content value="INVOICE_NOT_SENT">
                <MobileCancelsTableView
                  currentTab={currentTab}
                  cancels={invoiceNotSentCancels}
                  checkedCancelMap={checkedNotSentInvoiceCancelMap}
                  availableCouponMasters={couponMasters ?? []}
                  selectableCancelTypes={selectableCancelTypes}
                  editingRowMap={editingRowMap}
                  enableDefaultCouponMasterForNoConfirmationCancel={
                    enableDefaultCouponMasterForNoConfirmationCancel
                  }
                  defaultCouponMaster={defaultCouponMaster}
                  updateRow={updateRow}
                  onEditCancelType={onEditCancelType}
                  onEditOriginPrice={onEditOriginPrice}
                  onClickUpdateButton={onClickUpdateButton}
                  onClickActionButton={onClickSendInvoice}
                  cancelRowEdit={cancelRowEdit}
                  onClickRowEdit={onClickRowEdit}
                  onClickCheckbox={(cancel) =>
                    onClickCheckbox(cancel, "INVOICE_NOT_SENT")
                  }
                  onClickUpdatePaymentStatusButton={
                    onClickUpdatePaymentStatusButton
                  }
                  onClickScrollUpTriangle={onClickScrollUpTriangle}
                />
              </Tabs.Content>
              <Tabs.Content value="INVOICE_SENT">
                <MobileCancelsTableView
                  currentTab={currentTab}
                  cancels={invoiceSentCancels}
                  editingRowMap={editingRowMap}
                  enableDefaultCouponMasterForNoConfirmationCancel={
                    enableDefaultCouponMasterForNoConfirmationCancel
                  }
                  availableCouponMasters={couponMasters ?? []}
                  selectableCancelTypes={selectableCancelTypes}
                  defaultCouponMaster={defaultCouponMaster}
                  updateRow={updateRow}
                  onEditCancelType={onEditCancelType}
                  onEditOriginPrice={onEditOriginPrice}
                  onClickUpdateButton={onClickUpdateButton}
                  onClickActionButton={onClickAbortClaim}
                  cancelRowEdit={cancelRowEdit}
                  onClickRowEdit={onClickRowEdit}
                />
              </Tabs.Content>
              <Tabs.Content value="PAID">
                <MobileCancelsTableView
                  currentTab={currentTab}
                  cancels={paidCancels}
                  editingRowMap={editingRowMap}
                  enableDefaultCouponMasterForNoConfirmationCancel={
                    enableDefaultCouponMasterForNoConfirmationCancel
                  }
                  availableCouponMasters={couponMasters ?? []}
                  selectableCancelTypes={selectableCancelTypes}
                  defaultCouponMaster={defaultCouponMaster}
                  updateRow={updateRow}
                  onEditCancelType={onEditCancelType}
                  onEditOriginPrice={onEditOriginPrice}
                  onClickUpdateButton={onClickUpdateButton}
                  onClickActionButton={onClickSendInvoice}
                  cancelRowEdit={cancelRowEdit}
                  onClickRowEdit={onClickRowEdit}
                />
              </Tabs.Content>
              <Tabs.Content value="NOT_SUBJECT">
                <MobileCancelsTableView
                  currentTab={currentTab}
                  cancels={notSubjectCancels}
                  checkedCancelMap={checkedNotSubjectCancelIdMap}
                  editingRowMap={editingRowMap}
                  enableDefaultCouponMasterForNoConfirmationCancel={
                    enableDefaultCouponMasterForNoConfirmationCancel
                  }
                  availableCouponMasters={couponMasters ?? []}
                  selectableCancelTypes={selectableCancelTypes}
                  defaultCouponMaster={defaultCouponMaster}
                  updateRow={updateRow}
                  onEditCancelType={onEditCancelType}
                  onEditOriginPrice={onEditOriginPrice}
                  onClickUpdateButton={onClickUpdateButton}
                  onClickActionButton={onClickSendInvoice}
                  cancelRowEdit={cancelRowEdit}
                  onClickRowEdit={onClickRowEdit}
                  onClickCheckbox={(cancel) =>
                    onClickCheckbox(cancel, "NOT_SUBJECT")
                  }
                  onClickUpdatePaymentStatusButton={
                    onClickUpdatePaymentStatusButton
                  }
                  onClickScrollUpTriangle={onClickScrollUpTriangle}
                />
              </Tabs.Content>
              <Tabs.Content value="ENCOURAGING_RERESERVATION_NOT_SCHEDULED">
                <MobileCancelsForRereservationView
                  currentTab="ENCOURAGING_RERESERVATION_NOT_SCHEDULED"
                  cancels={encourageRereservationMessageNotSentCancels}
                  editingRereservationRowMap={editingRereservationRowMap}
                  updateEditingRereservationRow={updateEditingRereservationRow}
                  onClickUpdateButton={onClickUpdateButtonForRereservation}
                  onClickActionButton={onClickScheduleEncourageRereservation}
                  onClickRereservationRowEdit={onClickRereservationRowEdit}
                  cancelRereservationRowEdit={cancelRereservationRowEdit}
                />
              </Tabs.Content>
              <Tabs.Content value="ENCOURAGING_RERESERVATION_SCHEDULED">
                <MobileCancelsForRereservationView
                  currentTab="ENCOURAGING_RERESERVATION_SCHEDULED"
                  cancels={encourageRereservationMessageSentCancels}
                  editingRereservationRowMap={editingRereservationRowMap}
                  updateEditingRereservationRow={updateEditingRereservationRow}
                  onClickUpdateButton={onClickUpdateButtonForRereservation}
                  onClickActionButton={() => {}}
                  onClickRereservationRowEdit={onClickRereservationRowEdit}
                  cancelRereservationRowEdit={cancelRereservationRowEdit}
                />
              </Tabs.Content>
              <Tabs.Content value="RERESERVED">
                <MobileCancelsForRereservationView
                  currentTab="RERESERVED"
                  cancels={rereservedCancels}
                  editingRereservationRowMap={editingRereservationRowMap}
                  updateEditingRereservationRow={updateEditingRereservationRow}
                  onClickUpdateButton={onClickUpdateButtonForRereservation}
                  onClickActionButton={() => {}}
                  onClickRereservationRowEdit={onClickRereservationRowEdit}
                  cancelRereservationRowEdit={cancelRereservationRowEdit}
                />
              </Tabs.Content>
            </div>
          ) : (
            <div>
              <Tabs.Content value="INVOICE_NOT_SENT">
                <CancelsTableView
                  currentTab={currentTab}
                  cancels={invoiceNotSentCancels}
                  editingRowMap={editingRowMap}
                  checkedCancelMap={checkedNotSentInvoiceCancelMap}
                  availableCouponMasters={couponMasters ?? []}
                  selectableCancelTypes={selectableCancelTypes}
                  enableDefaultCouponMasterForNoConfirmationCancel={
                    enableDefaultCouponMasterForNoConfirmationCancel
                  }
                  defaultCouponMaster={defaultCouponMaster}
                  updateRow={updateRow}
                  onEditCancelType={onEditCancelType}
                  onEditOriginPrice={onEditOriginPrice}
                  onClickUpdateButton={onClickUpdateButton}
                  onClickActionButton={onClickSendInvoice}
                  cancelRowEdit={cancelRowEdit}
                  onClickRowEdit={onClickRowEdit}
                  onClickCheckbox={(cancel) =>
                    onClickCheckbox(cancel, "INVOICE_NOT_SENT")
                  }
                />
              </Tabs.Content>
              <Tabs.Content value="INVOICE_SENT">
                <CancelsTableView
                  currentTab={currentTab}
                  cancels={invoiceSentCancels}
                  editingRowMap={editingRowMap}
                  availableCouponMasters={couponMasters ?? []}
                  selectableCancelTypes={selectableCancelTypes}
                  enableDefaultCouponMasterForNoConfirmationCancel={
                    enableDefaultCouponMasterForNoConfirmationCancel
                  }
                  defaultCouponMaster={defaultCouponMaster}
                  updateRow={updateRow}
                  onEditCancelType={onEditCancelType}
                  onEditOriginPrice={onEditOriginPrice}
                  onClickUpdateButton={onClickUpdateButton}
                  onClickActionButton={onClickAbortClaim}
                  cancelRowEdit={cancelRowEdit}
                  onClickRowEdit={onClickRowEdit}
                />
              </Tabs.Content>
              <Tabs.Content value="PAID">
                <CancelsTableView
                  currentTab={currentTab}
                  cancels={paidCancels}
                  editingRowMap={editingRowMap}
                  availableCouponMasters={couponMasters ?? []}
                  selectableCancelTypes={selectableCancelTypes}
                  enableDefaultCouponMasterForNoConfirmationCancel={
                    enableDefaultCouponMasterForNoConfirmationCancel
                  }
                  defaultCouponMaster={defaultCouponMaster}
                  updateRow={updateRow}
                  onEditCancelType={onEditCancelType}
                  onEditOriginPrice={onEditOriginPrice}
                  onClickUpdateButton={onClickUpdateButton}
                  onClickActionButton={onClickSendInvoice}
                  cancelRowEdit={cancelRowEdit}
                  onClickRowEdit={onClickRowEdit}
                />
              </Tabs.Content>
              <Tabs.Content value="NOT_SUBJECT">
                <CancelsTableView
                  currentTab={currentTab}
                  cancels={notSubjectCancels}
                  editingRowMap={editingRowMap}
                  checkedCancelMap={checkedNotSubjectCancelIdMap}
                  availableCouponMasters={couponMasters ?? []}
                  selectableCancelTypes={selectableCancelTypes}
                  enableDefaultCouponMasterForNoConfirmationCancel={
                    enableDefaultCouponMasterForNoConfirmationCancel
                  }
                  defaultCouponMaster={defaultCouponMaster}
                  updateRow={updateRow}
                  onEditCancelType={onEditCancelType}
                  onEditOriginPrice={onEditOriginPrice}
                  onClickUpdateButton={onClickUpdateButton}
                  onClickActionButton={onClickSendInvoice}
                  cancelRowEdit={cancelRowEdit}
                  onClickRowEdit={onClickRowEdit}
                  onClickCheckbox={(cancel) =>
                    onClickCheckbox(cancel, "NOT_SUBJECT")
                  }
                />
              </Tabs.Content>
              <Tabs.Content value="ENCOURAGING_RERESERVATION_NOT_SCHEDULED">
                <CancelsForRereservationTableView
                  currentTab="ENCOURAGING_RERESERVATION_NOT_SCHEDULED"
                  cancels={encourageRereservationMessageNotSentCancels}
                  editingRereservationRowMap={editingRereservationRowMap}
                  updateEditingRereservationRow={updateEditingRereservationRow}
                  onClickUpdateButton={onClickUpdateButtonForRereservation}
                  onClickActionButton={onClickScheduleEncourageRereservation}
                  onClickRereservationRowEdit={onClickRereservationRowEdit}
                  cancelRereservationRowEdit={cancelRereservationRowEdit}
                />
              </Tabs.Content>
              <Tabs.Content value="ENCOURAGING_RERESERVATION_SCHEDULED">
                <CancelsForRereservationTableView
                  currentTab="ENCOURAGING_RERESERVATION_SCHEDULED"
                  cancels={encourageRereservationMessageSentCancels}
                  editingRereservationRowMap={editingRereservationRowMap}
                  updateEditingRereservationRow={updateEditingRereservationRow}
                  onClickUpdateButton={onClickUpdateButtonForRereservation}
                  onClickActionButton={() => {}}
                  onClickRereservationRowEdit={onClickRereservationRowEdit}
                  cancelRereservationRowEdit={cancelRereservationRowEdit}
                />
              </Tabs.Content>
              <Tabs.Content value="RERESERVED">
                <CancelsForRereservationTableView
                  currentTab="RERESERVED"
                  cancels={rereservedCancels}
                  editingRereservationRowMap={editingRereservationRowMap}
                  updateEditingRereservationRow={updateEditingRereservationRow}
                  onClickUpdateButton={onClickUpdateButtonForRereservation}
                  onClickActionButton={() => {}}
                  onClickRereservationRowEdit={onClickRereservationRowEdit}
                  cancelRereservationRowEdit={cancelRereservationRowEdit}
                />
              </Tabs.Content>
            </div>
          )}
        </Tabs.Root>
      </div>
    </div>
  );
};

export default HomePage;
