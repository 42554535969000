import { gql } from "@apollo/client";

// FIXME: 名前ややこしいので graphql collocation する

// queries

export const couponMasterNameFragment = gql(/* GraphQL */ `
  fragment couponMasterName on CouponMaster {
    id
    name
  }
`);

export const salonAllFieldFragment = gql(/* GraphQL */ `
  fragment salonAllField on Salon {
    id
    name
    displayName
    address
    canEnqueueSyncCancels
    enableDefaultCouponMasterForNoConfirmationCancel
    selectableCancelTypes
    selectableCancelTypesWithoutUnspecifiedOnes
    defaultSelectedCancelTypeForCreatingCancel
    defaultCouponMaster {
      ...couponMasterName
    }
    userPermission {
      canUpdateSalon
      canUpsertClaimRule
      canCreateCouponMaster
      canUpdateSalonUserRole
      canSwitchSalonOwner
      canInviteUserSalon
      canDeleteSalonUser
      canActivateCouponMaster
      canDeactivateCouponMaster
      canUpdateDefaultCouponMasterSetting
    }
    featureFlag {
      salonId
      isEncouragingRereservationEnabled
    }
  }
`);

export const salonUserAllFieldFragment = gql(/* GraphQL */ `
  fragment salonUserAllField on SalonUser {
    id
    name
    email
    role
    createTime
    lastLoginTime
  }
`);

export const salonNameFragment = gql(/* GraphQL */ `
  fragment salonName on Salon {
    id
    name
    displayName
  }
`);

export const Me = gql(/* GraphQL */ `
  query GetMe {
    me {
      salon {
        ...salonAllField
      }
      salons {
        ...salonName
      }
      salonUser {
        ...salonUserAllField
      }
    }
  }
`);

export const cancelForListFragment = gql(/* GraphQL */ `
  fragment cancelForList on Cancel {
    id
    idv2
    salonId
    reserveId
    cancelType
    paymentStatus
    visitCounts
    isVisitCountInvalid
    reserveRoute
    originPrice
    invoicePrice
    name
    staffName
    paidTime
    phoneNumber
    dateVisit
    dateCancel
    cancelCouponMasterSpecifiedBy
    isCreatedByUi
    couponMaster {
      ...couponMasterName
    }
  }
`);

export const cancelForRereservationFragment = gql(/* GraphQL */ `
  fragment cancelForRereservation on Cancel {
    idv2
    salonId
    reserveId
    cancelType
    rereservationStatus
    visitCounts
    isVisitCountInvalid
    isCreatedByUi
    originPrice
    name
    staffName
    phoneNumber
    rereservedTime
    dateVisit
    dateCancel
    rereservation {
      cancelId
      scrapingTargetSiteReserveId
      price
      startTime
      salonStaffId
      staff {
        scrapingTargetSiteStaffId
        name
      }
    }
  }
`);

export const GetClaimedCancels = gql(/* GraphQL */ `
  query GetClaimedCancels {
    claimedCancels {
      cancels {
        ...cancelForList
      }
    }
  }
`);

export const GetNotClaimedCancels = gql(/* GraphQL */ `
  query GetNotClaimedCancels {
    notClaimedCancels {
      cancels {
        ...cancelForList
      }
    }
  }
`);

export const GetPaidCancels = gql(/* GraphQL */ `
  query GetPaidCancels {
    paidCancels {
      cancels {
        ...cancelForList
      }
    }
  }
`);

export const GetNotSubjectCancels = gql(/* GraphQL */ `
  query GetNotSubjectCancels {
    notSubjectCancels {
      cancels {
        ...cancelForList
      }
    }
  }
`);

export const GetEncourageRereservationMessageNotSentCancels =
  gql(/* GraphQL */ `
    query GetEncourageRereservationMessageNotSentCancels {
      encourageRereservationMessageNotSentCancels {
        cancels {
          ...cancelForRereservation
        }
      }
    }
  `);

export const GetEncourageRereservationMessageSentCancels = gql(/* GraphQL */ `
  query GetEncourageRereservationMessageSentCancels {
    encourageRereservationMessageSentCancels {
      cancels {
        ...cancelForRereservation
      }
    }
  }
`);

export const GetRereservedCancels = gql(/* GraphQL */ `
  query GetRereservedCancels {
    rereservedCancels {
      cancels {
        ...cancelForRereservation
      }
    }
  }
`);

export const GetSalonUsers = gql(/* GraphQL */ `
  query GetSalonUsers {
    salonUsers {
      salonUsers {
        ...salonUserAllField
      }
    }
  }
`);

export const couponMasterForListFragment = gql(/* GraphQL */ `
  fragment couponMasterForList on CouponMaster {
    id
    name
    targetName
    discountAmount
    discountPercent
    expireDurationDay
    minAmount
    cancelsAttachedToCouponMaster {
      relationSummary {
        invoiceCount
        issuedCount
        usedCount
      }
    }
  }
`);

export const GetCouponMasters = gql(/* GraphQL */ `
  query GetCouponMasters($param: CouponMastersParam!) {
    couponMasters(param: $param) {
      ...couponMasterForList
    }
  }
`);

export const GetClaimRule = gql(/* GraphQL */ `
  query GetClaimRule {
    claimRule {
      id
      text
    }
  }
`);

export const GetInvoicePrice = gql(/* GraphQL */ `
  query GetInvoicePrice($param: InvoicePriceParam!) {
    invoicePrice(param: $param) {
      invoicePrice
    }
  }
`);

// mutations

export const UpdateCancel = gql(/* GraphQL */ `
  mutation UpdateCancel($input: UpdateCancelInput!) {
    updateCancel(input: $input) {
      ...cancelForList
    }
  }
`);

export const UpdateCancelForRereservation = gql(/* GraphQL */ `
  mutation UpdateCancelForRereservation(
    $input: UpdateCancelForRereservationInput!
  ) {
    updateCancelForRereservation(input: $input) {
      ...cancelForList
    }
  }
`);

export const SendInvoice = gql(/* GraphQL */ `
  mutation SendInvoice($input: SendInvoiceInput!) {
    sendInvoice(input: $input) {
      idv2
    }
  }
`);

export const ScheduleEncourageRereservation = gql(/* GraphQL */ `
  mutation ScheduleEncourageRereservation(
    $input: ScheduleEncourageRereservationInput!
  ) {
    scheduleEncourageRereservation(input: $input) {
      cancel {
        idv2
      }
    }
  }
`);

export const AbortClaim = gql(/* GraphQL */ `
  mutation AbortClaim($input: AbortClaimInput!) {
    abortClaim(input: $input) {
      idv2
    }
  }
`);

export const CreateCouponMaster = gql(/* GraphQL */ `
  mutation CreateCouponMaster($input: CouponMasterInput!) {
    createCouponMaster(input: $input) {
      couponMaster {
        id
      }
      salon {
        ...salonAllField
      }
    }
  }
`);

export const ActivateCouponMaster = gql(/* GraphQL */ `
  mutation ActivateCouponMaster($input: ActivateCouponMasterInput!) {
    activateCouponMaster(input: $input) {
      id
    }
  }
`);

export const DeactivateCouponMaster = gql(/* GraphQL */ `
  mutation DeactivateCouponMasterV2($input: DeactivateCouponMasterInput!) {
    deactivateCouponMasterV2(input: $input) {
      couponMaster {
        id
      }
      salon {
        ...salonAllField
      }
    }
  }
`);

export const UpdateDefaultCouponMasterSetting = gql(/* GraphQL */ `
  mutation UpdateDefaultCouponMasterSetting(
    $input: UpdateDefaultCouponMasterSettingInput!
  ) {
    updateDefaultCouponMasterSetting(input: $input) {
      ...salonAllField
    }
  }
`);

export const Login = gql(/* GraphQL */ `
  mutation Login($salonId: Int) {
    login(salonId: $salonId) {
      salon {
        ...salonAllField
      }
      salons {
        ...salonName
      }
      salonUser {
        ...salonUserAllField
      }
    }
  }
`);

export const SwitchSalon = gql(/* GraphQL */ `
  mutation SwitchSalon($salonId: Int!) {
    switchSalon(salonId: $salonId) {
      salon {
        ...salonAllField
      }
      salons {
        ...salonName
      }
    }
  }
`);

export const UpdateSalon = gql(/* GraphQL */ `
  mutation UpdateSalon($input: UpdateSalonInput!) {
    updateSalon(input: $input) {
      ...salonAllField
    }
  }
`);

export const bulkUpdateCancelPaymentStatus = gql(/* GraphQL */ `
  mutation BulkUpdateCancelPaymentStatus(
    $input: BulkUpdateCancelPaymentStatusInput!
  ) {
    bulkUpdateCancelPaymentStatus(input: $input) {
      id
      salonId
      reserveId
      cancelType
      paymentStatus
      visitCounts
      reserveRoute
      originPrice
      invoicePrice
      name
      phoneNumber
      dateVisit
      dateCancel
      isCouponEnabled
    }
  }
`);

export const UpsertClaimRule = gql(/* GraphQL */ `
  mutation UpsertClaimRule($input: UpsertClaimRuleInput!) {
    upsertClaimRule(input: $input) {
      id
      text
    }
  }
`);

export const LoadCancels = gql(/* GraphQL */ `
  mutation LoadCancels($input: LoadCancelsInput!) {
    loadCancels(input: $input) {
      ok
    }
  }
`);

export const CreateCancel = gql(/* GraphQL */ `
  mutation CreateCancel($input: CreateCancelInput!) {
    createCancel(input: $input) {
      cancel {
        id
        idv2
      }
    }
  }
`);

export const UpdateSalonUserRole = gql(/* GraphQL */ `
  mutation UpdateSalonUserRole($input: UpdateSalonUserRoleInput!) {
    updateSalonUserRole(input: $input) {
      salonUser {
        ...salonUserAllField
      }
    }
  }
`);

export const SwitchSalonOwner = gql(/* GraphQL */ `
  mutation SwitchSalonOwner($input: SwitchSalonOwnerInput!) {
    switchSalonOwner(input: $input) {
      ok
    }
  }
`);

export const DeleteSalonUser = gql(/* GraphQL */ `
  mutation DeleteSalonUser($input: DeleteSalonUserInput!) {
    deleteSalonUser(input: $input) {
      ok
    }
  }
`);

export const InviteSalonUser = gql(/* GraphQL */ `
  mutation InviteSalonUser($input: InviteSalonUserInput!) {
    inviteSalonUser(input: $input) {
      ok
    }
  }
`);
