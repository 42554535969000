import { useApolloClient, useLazyQuery } from "@apollo/client";
import {
  GetRereservationDocument,
  GetRereservationQuery,
  GetRereservationQueryVariables,
  RereservationFragmentDoc,
} from "gql/public/__generated__/graphql";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./RereservationCompletePage.module.scss";
import CompleteIcon from "components/ui/CompleteIcon/CompleteIcon";
import { getFragmentData } from "gql/public/__generated__";
import { formatDateUntilDay, formatHourMinuteOfDate } from "utils/date";
import { toStringWithCommas } from "utils/strings/number";

const RereservationCompletePage = () => {
  const cancelID = useParams().cancel_id;

  const [getRereservation, getRereservationResult] = useLazyQuery<
    GetRereservationQuery,
    GetRereservationQueryVariables
  >(GetRereservationDocument);
  const rereservation = getFragmentData(
    RereservationFragmentDoc,
    getRereservationResult.data?.Rereservation
  );

  const menusAndCoupons = rereservation?.menusAndCoupons;
  const menusAndCouponsLength = menusAndCoupons?.names.length ?? 0;
  const cancelConditionTexts =
    menusAndCouponsLength > 0
      ? rereservation?.salon.cancelConditionTextsForMenu
      : rereservation?.salon.cancelConditionTextsForNoMenu;

  useEffect(() => {
    if (!cancelID) {
      return;
    }
    getRereservation({ variables: { param: { cancelId: cancelID } } });
  }, [cancelID, getRereservation]);

  const durationMinute = useMemo(() => {
    return rereservation?.durationMinute + "分";
  }, [rereservation?.durationMinute]);
  const menuNames = useMemo(() => {
    const names = rereservation?.menusAndCoupons?.names;
    if (!names || names.length === 0) {
      return ["なし"];
    }

    const texts: string[] = [];
    for (const name of names) {
      texts.push(`・${name}`);
    }
    return texts;
  }, [rereservation?.menusAndCoupons?.names]);
  const price = useMemo(() => {
    return "¥" + toStringWithCommas(rereservation?.price ?? 0); // FIXME:
  }, [rereservation?.price]);
  const staffName = useMemo(() => {
    return rereservation?.staff?.name ?? "なし";
  }, [rereservation?.staff?.name]);

  if (getRereservationResult.loading) {
    return <div>読み込み中...</div>;
  }

  return (
    <div className={styles.complete_page}>
      <div className={styles.icon_container}>
        <CompleteIcon />
      </div>
      <h2>再予約が確定しました</h2>
      <div className={styles.box}>
        <div className={styles.box_title}>■ご予約内容</div>
        <div className={styles.main_content_container}>
          <table>
            <tr>
              <td className={styles.title}>ご予約店舗</td>
              <td className={styles.content}>{rereservation?.salon.name}</td>
            </tr>
            <tr>
              <td className={styles.title}>ご予約日時</td>
              <td className={styles.content}>
                {rereservation?.startTime
                  ? formatDateUntilDay(new Date(rereservation.startTime))
                  : ""}
              </td>
            </tr>
            <tr>
              <td className={styles.title}>ご予約時間</td>
              <td className={styles.content}>
                {rereservation?.startTime
                  ? formatHourMinuteOfDate(new Date(rereservation.startTime))
                  : ""}
              </td>
            </tr>
            <tr>
              <td className={styles.title}>施術時間</td>
              <td className={styles.content}>{durationMinute}</td>
            </tr>

            <br />

            <tr>
              <td className={styles.title}>ご予約メニュー</td>
              <td className={styles.content}>
                {menuNames.map((name, i) => (
                  <div key={i}>{name}</div>
                ))}
              </td>
            </tr>
            <tr>
              <td className={styles.title}>ご予約金額</td>
              <td className={styles.content}>{price}</td>
            </tr>
            <tr>
              <td className={styles.title}>指名スタッフ</td>
              <td className={styles.content}>{staffName}</td>
            </tr>

            <br />

            <tr>
              <td className={styles.title}>お客様名</td>
              <td className={styles.content}>{rereservation?.customer.name}</td>
            </tr>
          </table>
        </div>
      </div>

      {cancelConditionTexts && cancelConditionTexts.length > 0 && (
        <div className={styles.box}>
          <div className={styles.box_title}>■キャンセルポリシー</div>
          <div className={styles.cancel_policy_container}>
            <div>
              直前のご予約変更やキャンセル、無断キャンセル、遅刻などがありますと、
            </div>
            <div>
              他のお客様にもご迷惑がかかるだけでなく、時間をロスしてしまい、
            </div>
            <div>その分、余計なコストが発生してしまいます。 </div>

            <br />

            <div>
              そうすると、その分の料金を上げさせていただかなければいけなくなります。{" "}
            </div>
            <div>
              少しでも、そのような事態を避けるための取り組みの一環として、
            </div>
            <div> キャンセル料を以下のように設定させて頂いております。 </div>

            <br />

            <div>
              <b>＜キャンセル料＞</b>
            </div>
            {cancelConditionTexts?.map((text, i) => {
              return (
                <div>
                  {" "}
                  <b key={i}>{text}</b>{" "}
                </div>
              );
            })}

            <br />
            <div>ご理解とご協力を何卒宜しくお願い致します。</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RereservationCompletePage;
