import { isValidPhoneNumber } from "utils/phoneNumber";
import * as HoverCard from "@radix-ui/react-hover-card";
import styles from "./ActionButton.module.scss";
import { isValidCancelTypeToSendInvoice } from "domain/home";
import {
  CancelForListFragment,
  CancelForRereservationFragment,
  CancelPaymentStatus,
  CancelRereservationStatus,
} from "gql/__generated__/graphql";
import classNames from "classnames";
import {
  invalidCancelTypeToSendInvoiceMessage,
  invalidPhoneNumberMessage,
} from "../../home.common.logic";

type Props = {
  row?: CancelForListFragment;
  rereservationRow?: CancelForRereservationFragment;
  isEditingRow: boolean;
  onClickUpdateButton: () => void;
  onClickActionButton: () => void;
};

const getSpanClassAndText = (
  paymentStatus?: CancelPaymentStatus,
  rereservationStatus?: CancelRereservationStatus,
  rereservationRowSalonId?: number
) => {
  let spanClass = "";
  let text = "";
  if (paymentStatus) {
    if (paymentStatus === "INVOICE_NOT_SENT") {
      spanClass = `${styles.background_dark_orange} ${styles.button_active}`;
      text = "請求する";
    } else if (
      paymentStatus === "INVOICE_SENT" ||
      paymentStatus === "SCHEDULED_TO_CLAIM"
    ) {
      spanClass = `${styles.background_dark_orange} ${styles.button_active}`;
      text = "取消する";
    } else if (paymentStatus === "CLAIM_ABORTED") {
      spanClass = styles.background_gray;
      text = "取消済";
    } else {
      // 何も表示しない
    }
  }

  if (rereservationStatus) {
    if (
      rereservationStatus === "ENCOURAGING_RERESERVATION_NOT_SCHEDULED" ||
      rereservationStatus === "CANNOT_ENCOURAGE" ||
      (rereservationRowSalonId &&
        [3, 72].includes(rereservationRowSalonId) &&
        rereservationStatus === "INFORMATION_NOT_ENOUGH") // FIXME:
    ) {
      spanClass = `${styles.background_dark_orange} ${styles.button_active}`;
      text = "送付する";
    }

    if (rereservationStatus === "INFORMATION_NOT_ENOUGH") {
      text = "送付する";
    }
  }

  return { spanClass, text };
};

const getErrorMessages = (
  row?: CancelForListFragment,
  rereservationRow?: CancelForRereservationFragment
) => {
  const errorMessages: string[] = [];
  if (row && !isValidPhoneNumber(row.phoneNumber)) {
    errorMessages.push(invalidPhoneNumberMessage);
  }
  if (row && !isValidCancelTypeToSendInvoice(row.cancelType)) {
    errorMessages.push(invalidCancelTypeToSendInvoiceMessage);
  }

  if (rereservationRow && !isValidPhoneNumber(rereservationRow.phoneNumber)) {
    errorMessages.push(invalidPhoneNumberMessage);
  }

  return errorMessages;
};

const ActionButton = (props: Props) => {
  const {
    row,
    rereservationRow,
    isEditingRow,
    onClickUpdateButton,
    onClickActionButton,
  } = props;
  if (row?.paymentStatus === "NOT_SUBJECT") {
    return <span></span>;
  }
  if (
    rereservationRow &&
    ![
      "ENCOURAGING_RERESERVATION_NOT_SCHEDULED",
      "INFORMATION_NOT_ENOUGH",
    ].includes(rereservationRow.rereservationStatus)
  ) {
    return <span></span>;
  }

  let { spanClass, text } = getSpanClassAndText(
    row?.paymentStatus,
    rereservationRow?.rereservationStatus,
    rereservationRow?.salonId
  );

  const errorMessages = getErrorMessages(row, rereservationRow);
  if (errorMessages.length > 0) {
    spanClass = classNames(
      styles.background_light_blue,
      styles.button_inactive
    );
  }

  const onClick = () => {
    if (errorMessages.length > 0) {
      return;
    }
    onClickActionButton();
  };

  return isEditingRow ? (
    <span
      className={`${styles.background_dark_orange} ${styles.button_active} ${styles.table_elm_background}`}
      onClick={onClickUpdateButton}
    >
      更新
    </span>
  ) : (
    // hovercardは共通component作りたい
    <HoverCard.Root openDelay={300}>
      <HoverCard.Trigger>
        <span
          className={`${spanClass} ${styles.table_elm_background}`}
          onClick={onClick}
        >
          {text}
        </span>
      </HoverCard.Trigger>
      {errorMessages.length > 0 && (
        <HoverCard.Portal>
          <HoverCard.Content className={styles.hover_card_content}>
            {errorMessages.map((message, _) => (
              <div>{message}</div>
            ))}
            <HoverCard.Arrow className={styles.arrow} />
          </HoverCard.Content>
        </HoverCard.Portal>
      )}
    </HoverCard.Root>
  );
};

export default ActionButton;
