import styles from "./CompleteIcon.module.scss";

const CompleteIcon = () => {
  return (
    <svg className={styles.circle} width="50" height="50" viewBox="0 0 100 100">
      <path
        className={styles.outline}
        d="M 50, 90
            a 40,40 0 1,1 0,-80
            a 40,40 0 1,1 0,80"
        fill="none"
        stroke="#FF8F4F"
        stroke-width="2"
      />
      <path
        className={styles.checkmark}
        d="M20,50 L40,70 L80,30"
        fill="none"
        stroke="#FF8F4F"
        stroke-width="6"
      />
    </svg>
  );
};

export default CompleteIcon;
