import { useQuery } from "@apollo/client";
import { getFragmentData } from "gql/__generated__";
import {
  GetMeDocument,
  GetMeQuery,
  GetMeQueryVariables,
  SalonAllFieldFragmentDoc,
  FeatureFlag,
} from "gql/__generated__/graphql";

type UseFeatureFlagReturn = {
  refetch: () => void;
  featureFlag?: FeatureFlag;
};

export const useFeatureFlag = (): UseFeatureFlagReturn => {
  const { data, refetch } = useQuery<GetMeQuery, GetMeQueryVariables>(
    GetMeDocument,
    {
      fetchPolicy: "cache-first",
    }
  );
  const salon = getFragmentData(SalonAllFieldFragmentDoc, data?.me.salon);
  return {
    refetch,
    featureFlag: salon?.featureFlag,
  };
};
