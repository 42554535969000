import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import * as RadixSelect from "@radix-ui/react-select";
import classNames from "classnames";
import React from "react";
import styles from "./Select.module.scss";

export type Option = {
  value: string;
  texts: string[];
};

type Props = {
  value: string;
  options: Option[];
  onChange: (value: string) => void;
};

// radix-ui/select: https://www.radix-ui.com/primitives/docs/components/select
const Select = (props: Props) => {
  return (
    <RadixSelect.Root value={props.value} onValueChange={props.onChange}>
      <RadixSelect.Trigger className={styles.trigger}>
        <RadixSelect.Value />
        <RadixSelect.Icon>
          <ChevronDownIcon />
        </RadixSelect.Icon>
      </RadixSelect.Trigger>
      <RadixSelect.Portal>
        <RadixSelect.Content className={styles.content}>
          <RadixSelect.ScrollUpButton>
            <ChevronUpIcon />
          </RadixSelect.ScrollUpButton>
          <RadixSelect.Viewport>
            <RadixSelect.Group>
              {/* <RadixSelect.Label className="SelectLabel">
                Fruits
              </RadixSelect.Label> */}
              {props.options.map((option, i) => (
                <div>
                  <SelectItem value={option.value}>
                    <div>
                      {option.texts.map((text) => (
                        <p className={styles.text_line}>{text}</p>
                      ))}
                    </div>
                  </SelectItem>

                  {i === props.options.length - 1 ? null : (
                    <div className={styles.separator} />
                  )}
                </div>
              ))}
            </RadixSelect.Group>
          </RadixSelect.Viewport>
          <RadixSelect.ScrollDownButton>
            <ChevronDownIcon />
          </RadixSelect.ScrollDownButton>
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </RadixSelect.Root>
  );
};

const SelectItem = React.forwardRef<
  HTMLDivElement,
  {
    value: string;
    disabled?: boolean;
    className?: string;
    children: React.ReactNode;
  }
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <RadixSelect.Item
      className={classNames(styles.item, className)}
      {...props}
      ref={forwardedRef}
    >
      <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
      <RadixSelect.ItemIndicator>
        <CheckIcon />
      </RadixSelect.ItemIndicator>
    </RadixSelect.Item>
  );
});

export default Select;
